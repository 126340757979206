import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "signup-page" }
const _hoisted_2 = { class: "login-normal" }
const _hoisted_3 = { class: "inner login-container" }
const _hoisted_4 = { class: "login-title" }
const _hoisted_5 = { class: "tagline" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "login-body" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 2,
  class: "submitted-successful"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "strike" }
const _hoisted_13 = { class: "or-text" }
const _hoisted_14 = { class: "xid-hint" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "xid-install" }
const _hoisted_17 = {
  href: "https://apps.apple.com/jp/app/xid/id1495147544",
  target: "_blank"
}
const _hoisted_18 = ["src"]
const _hoisted_19 = {
  href: "https://play.google.com/store/apps/details?id=me.x.id&hl=en",
  target: "_blank",
  class: "google-img"
}
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "inner login-container regis" }
const _hoisted_22 = { class: "regis-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_ValidationPasswordField = _resolveComponent("ValidationPasswordField")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_XidButton = _resolveComponent("XidButton")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("login.signupTagline")), 1),
          _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.$t("login.signupTitle")), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (!_ctx.signUpSuccessful)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_ValidationTextField, {
                  value: _ctx.email,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                  rules: "required|email",
                  class: "login-input-field",
                  "max-length": 320,
                  message: {
                error_required: _ctx.$t('errors.required'),
                error_format_email: _ctx.$t('errors.formatEmail'),
              },
                  name: "email",
                  placeholder: _ctx.$t('login.signupEmail')
                }, null, 8, ["value", "message", "placeholder"]),
                _createVNode(_component_ValidationPasswordField, {
                  rules: "password|required",
                  name: "password",
                  class: "login-input-field",
                  placeholder: _ctx.$t('login.signupPassword')
                }, null, 8, ["placeholder"]),
                _createVNode(_component_ValidationPasswordField, {
                  rules: "password|required",
                  name: "repeatPassword",
                  class: "login-input-field",
                  placeholder: _ctx.$t('login.signupPasswordConfirm'),
                  onOnBlur: _ctx.validateRepeatPassword
                }, null, 8, ["placeholder", "onOnBlur"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.signUpSuccessful)
            ? (_openBlock(), _createBlock(_component_FlatButton, {
                key: 1,
                class: "login-btn w-full",
                text: _ctx.$t('login.signupCreateBtn'),
                "on-click": _ctx.submit
              }, null, 8, ["text", "on-click"]))
            : _createCommentVNode("", true),
          (_ctx.signUpSuccessful)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("img", {
                  src: require('icons/mail-confirm.svg')
                }, null, 8, _hoisted_10),
                _createElementVNode("p", {
                  innerHTML: _ctx.$t('login.signupEmailConfirmText', { email: _ctx.email })
                }, null, 8, _hoisted_11)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("login.signupOr")), 1)
          ]),
          _createVNode(_component_XidButton, {
            class: "login-xid-btn w-full",
            text: _ctx.$t('login.signupXidBtn'),
            onOnClick: _ctx.xidLogin
          }, null, 8, ["text", "onOnClick"]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("p", {
              innerHTML: 
                _ctx.$t('login.signupXidHint2')
              
            }, null, 8, _hoisted_15),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("a", _hoisted_17, [
                _createElementVNode("img", {
                  src: require('icons/app_store.svg'),
                  alt: "app store image",
                  class: "app-store-img"
                }, null, 8, _hoisted_18)
              ]),
              _createElementVNode("a", _hoisted_19, [
                _createElementVNode("img", {
                  src: require('icons/google_play.svg'),
                  alt: "google play image",
                  class: "google-play-img"
                }, null, 8, _hoisted_20)
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("p", _hoisted_22, [
          _createTextVNode(_toDisplayString(_ctx.$t("login.signupLoginText")) + " ", 1),
          _createVNode(_component_router_link, {
            to: { name: 'Login' },
            class: "link",
            tag: "button"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("login.thisText")), 1)
            ]),
            _: 1
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("common.dot")), 1)
        ])
      ])
    ])
  ]))
}