
// @ is an alias to /src
import { defineComponent, ref, watch } from "vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import ValidationPasswordField from "@/components/atomics/ValidationPasswordField.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import XidButton from "@/components/atomics/XIDButton.vue";
import { RepositoryFactory, AuthRepository } from "@/lib/https";
import { useFieldError, useForm} from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import errorsText from "@/locales/ja/errors";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { hasNumber, hasAlphabet } from "@/lib/utility/stringUtil";

const baseDomain: string = process.env.VUE_APP_API_URL as string;

export default defineComponent({
  name: "Signup",
  components: {
    ValidationTextField,
    ValidationPasswordField,
    FlatButton,
    XidButton,
  },
  props: {
    isSentConfirmMail: { type: Boolean, default: false },
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const { companyId } = route.query;
    const invitationEmail = route.query.email;
    const email = ref(invitationEmail);
    const form = useForm({
      initialValues: {
        email: email.value,
        password: "",
        repeatPassword: "",
        companyId: companyId ?? "",
        partner: route.query.partner || ""
      },
    });
    const { signUp } = authentication();
    const { formHandle } = useFormHandler(form, signUp);
    const signUpSuccessful = ref(false);
    const submit = function () {
      //validate password
      if (
        !hasAlphabet(form.values.password) ||
        !hasNumber(form.values.password) ||
        form.values.password.length < 8
      ) {
        form.setErrors({
          password: errorsText.invalidPassword,
        });
        return;
      }
      if (form.values.password != form.values.repeatPassword) {
        form.setErrors({
          repeatPassword: errorsText.passwordRepeatError,
        });
        return;
      }

      return formHandle()
        .then((e) => {
          signUpSuccessful.value = e;
        })
        .catch((e) => {
          form.setErrors({
            email: e.data.message,
          });
        });
    };

    const validateRepeatPassword = () => {
      if (form.values["password"] != form.values["repeatPassword"])
        form.setErrors({ repeatPassword: t("errors.passwordRepeatError") });
    };

    const xidLogin = () => {
      window.open(`${baseDomain}/auth/login-by-xid?partner=${route.query.partner || ""}&companyId=${route.query.companyId || ""}`, "_self");
    }

    return {
      signUp,
      submit,
      signUpSuccessful,
      email,
      validateRepeatPassword,
      xidLogin
    };
  },
});

// Authentication
const authentication = () => {
  const { signUp } =
    RepositoryFactory.getRepository<AuthRepository>(AuthRepository);
  return {
    signUp,
  };
};
